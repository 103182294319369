.ms-TextField-fieldGroup {
    background-color: transparent !important;
}

.ms-TextField-prefix {
    background-color: transparent !important;
}

.ms-Dropdown-title {
    background-color: transparent !important;
}

.CircularProgressbar .CircularProgressbar-text {
    fill: black;
    font-weight: 700;
    font-size: 24px;
}

img {
    user-drag: none;
    user-select: none;
}

html {
    height: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    user-drag: none;
    user-select: none;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#root {
    height: 100%;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes animate {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}

/* Standard syntax */
@keyframes animate {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes doRotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.plane {
    animation: doRotate 60s linear infinite;
    transform-origin: center center;
}
